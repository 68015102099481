import UserServices from "@/ApiServices/UserServices";

export default {
	namespaced: true,
	state: {
		user_authenticated: false,
		user_details: {},
	},
	mutations: {
		SET_USER_AUTHENTICATED(state, val) {
			state.user_authenticated = val;
		},
		SET_USER_DETAILS(state, val) {
			state.user_details = val;
		},
	},
	actions: {
		setUserDetails: async ({ commit }) => {
			await commit("SET_USER_DETAILS", {});
			UserServices.getUserDetails()
				.then(async (response) => {
					if (response.data.status) {
						localStorage.removeItem("user_details");
						localStorage.setItem(
							"user_details",
							JSON.stringify(response.data.data)
						);
						await commit("SET_USER_DETAILS", response.data.data);
					}
				})
				.catch((err) => {
					console.log("Error in getting user details ", err);
				});
		},
	},
};
