<template>
  <div>
    <b-navbar toggleable="lg" fixed="top" class="ohlive-header">
      <!--Start Desktop Menu-->

      <div class="d-none d-xl-block mt-3 w-100">
        <div class="container d-flex align-items-center">
          <b-navbar-brand href="/"
            ><img src="@/assets/images/logo/OhliveLogo.png" alt="Ohlive"
          /></b-navbar-brand>
          <b-navbar-brand class="mx-4 cursor-pointer speaker-class" @click="playRandomAudio"
            ><img src="@/assets/images/speaker-filled-audio-tool.png" alt="Ohlive"
          /></b-navbar-brand>
          <b-collapse id="nav-text-collapse" is-nav>
            <b-navbar-nav class="align-items-center">
              <b-navbar-nav class="ml-auto nav-items">
                <b-nav-item to="/browse-recipes">
                  {{ $t("tabs.recipe") }}</b-nav-item
                >
                <b-nav-item to="/how-it-works">{{
                  $t("HeaderFooter.How it works")
                }}</b-nav-item>
                <b-nav-item to="/contact">{{
                  $t("HeaderFooter.Get in Touch")
                }}</b-nav-item>
                <b-nav-item to="/about">{{
                  $t("HeaderFooter.About")
                }}</b-nav-item>
              </b-navbar-nav>
              <b-navbar-nav>
                <b-nav-item to="/register">
                  <b-button class="btn-auth btn-primary">{{
                    $t("HeaderFooter.SignupForFree")
                  }}</b-button></b-nav-item
                >
                <b-nav-item to="/login">
                  <b-button class="btn-auth btn-secondary">{{
                    $t("HeaderFooter.Login")
                  }}</b-button></b-nav-item
                >
              </b-navbar-nav>
              <locale-switch />

            </b-navbar-nav>
          </b-collapse>

          
        </div>
      </div>
      <!--End Desktop Menu-->

      <!--Start Mobile Menu-->
      <div class="d-block d-md-block d-xl-none w-100">
        <div class="mob-header">
          <div class="mob-header--grid">
            <div>
              <a class="m-logo" href="/">
                <figure>
                  <img
                    src="@/assets/images/logo/OhliveLogo.png"
                    alt="Ohlive"
                    class="w-100"
                  />
                </figure>
              </a>
            </div>
            <div class="d-flex align-items-center justify-content-end">
              <div>
                <locale-switch />
              </div>
              <div @click="showMenu" class="menu-icon mx-2">
                <figure>
                  <img src="@/assets/images/menu.svg" alt="Menu" />
                </figure>
                <!-- <span>Menu</span> -->
              </div>
            </div>
          </div>
          <div class="mob-header--border">
            <figure>
              <img src="@/assets/images/icon/line.svg" alt="" class="w-100" />
            </figure>
          </div>
        </div>
        <aside
          class="mobile-menu"
          :class="isMobileMenu ? 'show-menu' : 'hide-menu'"
        >
          <div class="mob-header">
            <div class="mob-header--grid">
              <a class="m-logo" href="/">
                <figure>
                  <img
                    src="@/assets/images/logo/OhliveLogo.png"
                    alt="Ohlive"
                    class="w-100"
                  />
                </figure>
              </a>
              <div @click="hideMenu" class="menu-cross-icon text-end">
                <figure>
                  <img src="@/assets/images/cross-circle.svg" alt="Menu" />
                </figure>
                <!-- <span>Menu</span> -->
              </div>
            </div>
            <div class="mob-header--border">
              <figure>
                <img src="@/assets/images/icon/line.svg" alt="" class="w-100" />
              </figure>
            </div>
          </div>

          <div>
            <ul class="mob-side-list">
              <li>
                <a href="/how-it-works">
                  <span> {{ $t("HeaderFooter.How it works") }}</span></a
                >
              </li>
              <li>
                <a href="/browse-recipes">
                  <span>{{ $t("HeaderFooter.Browse Recipe") }}</span>
                </a>
              </li>
              <li>
                <a href="/how-it-works">
                  <span> {{ $t("HeaderFooter.How it works") }}</span></a
                >
              </li>
              <li>
                <a href="/contact">
                  <span>{{ $t("HeaderFooter.Get in Touch") }}</span></a
                >
              </li>
              <li>
                <a href="/about">{{ $t("HeaderFooter.About") }}</a>
              </li>
            </ul>
          </div>
          <div class="d-flex justify-content-between mb-3 px-3">
            <a href="/register">
              <b-button class="btn btn-auth btn-primary">{{
                    $t("HeaderFooter.SignupForFree")
                  }}</b-button>
            </a>
            <a href="/login">
              <b-button class="btn btn-auth btn-secondary"
              >{{
                    $t("HeaderFooter.Login")
                  }}</b-button
            >
            </a>
            
          </div>
          <!-- <locale-switch /> -->

        </aside>
      </div>

      <!--End Mobile Menu-->

      <!--End Mobile Menu-->
    </b-navbar>
  </div>
</template>
<script>
import LocaleSwitch from "@/components/partials/LocaleSwitch.vue";

const EnglishSound1 = require('@/assets/audio/englishaudio1.mp4');
const EnglishSound2 = require('@/assets/audio/englishaudio2.mp4');
const SpanishSound1 = require('@/assets/audio/spanishaudio1.mp4');
const SpanishSound2 = require('@/assets/audio/spanishaudio2.mp4');

const audioFilesEn = [EnglishSound1, EnglishSound2]
const audioFilesEs = [SpanishSound1, SpanishSound2]

export default {
  name: "HeaderPage",
  components: {
    LocaleSwitch,
  },
  data() {
    return {
      isMobileMenu: false,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    showMenu: function () {
      this.isMobileMenu = true;
    },
    hideMenu: function () {
      this.isMobileMenu = false;
    },
    playRandomAudio() {
      const SelectedLang = localStorage.getItem('locale');
      if(SelectedLang == "en") {
        const randomIndex = Math.floor(Math.random() * audioFilesEn.length);
        const audio = new Audio(audioFilesEn[randomIndex]);
        audio.play();
      }
      else {
        const randomIndex = Math.floor(Math.random() * audioFilesEs.length);
        const audio = new Audio(audioFilesEs[randomIndex]);
        audio.play();
      }
    },
    handleScroll: function () {
      const element = document.querySelector(".ohlive-header");
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        element.classList.add("scrolled");
      } else {
        element.classList.remove("scrolled");
      }
    },
  },
};
</script>
<style scoped></style>
