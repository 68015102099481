export default {
  HeaderFooter: {
    "Trust Us": "Confía en nosotros. :)",
    SignupForFree: "Inscribirse",
    Login: "Acceso",
    "Browse Recipe": "Explorar Receta",
    "How it works": "¿Cómo Funciona?",

    "Get in Touch": "Ponerse en contacto",
    About: "Sobre",
    "Browse Recipes": "Examinar Recetas",
    Blog: "",

    Copyright: "Derechos de autor",
    "Pvt Ltd": "Ohlive Ventures",
    Rights: "Reservados todos los derechos.",
    TermsCondition: "Términos de uso",
  },
  Footer: {
    "Accept All": "Aceptar todo",
    "We Value": "Valoramos tu privacidad",
    "We use cookies": "Utilizamos cookies propias y de terceros para mejorar su experiencia de navegación, mostrar anuncios o contenido personalizados y analizar nuestro tráfico. Al hacer clic en 'Aceptar todo', usted acepta nuestro uso de cookies.",
  },
  homePage: {
    "WELCOME TEXT":
      "La forma más fácil de planificar tus comidas, cocinar, ahorrar tiempo y dinero, sin más preocupaciones. Pruébalo y forma parte de nuestra comunidad.",
    "Trust Us": "Confía en nosotras. :)",
    "weltext":"¡Cada vez somos más personas cuidándonos y disfrutando de la Ohlive!",

    "Scroll down to": "Desplácese hacia abajo para obtener más información...",
    "Invest your time":
      "Invierte tu tiempo en las cosas importantes, como cuidar de ti y los tuyos.",
    you: "tú ",
    and: "y",
    "Ohlive takes care":
      "Ohlive se encarga de todo lo demás. Qué cocinar, cómo cocinar o dónde comprar.",
    "A truly inclusive":
      "Un planificador de comidas verdaderamente inclusivo para",
    What: "¿Qué",
    "to cook?": "cocinar?",
    "What to cook?":
      "Un planificador de comidas semanal súper fácil de usar, creado en torno a las preferencias y limitaciones de usted y su familia. Vaya con la recomendación de Ohlive o seleccione una receta de más de 500 recetas.",
    Groceries: "¿Que ",
    needed: " ingredientes necesito?",
    "Groceries needed":
      "Una lista totalmente automatizada de todos los alimentos que necesita se prepara tan pronto como su plan de comidas está listo, comprado automáticamente con nuestros socios de alimentos. Llévalo a casa sin problemas.",
    How: "¿Cómo ",
    "A meal plan":
      "Un plan de comidas no tiene sentido si no se puede diseñar en torno a usted y el estilo de vida de su familia. Ohlive lo sabía antes de que te despertaras hoy por la mañana y leyeras esto. :)",
    "How to cook?":
      "Use las recetas propias de Ohlive y asegúrese de activar nuestro 'modo de receta' súper genial cuando esté a punto de cocinar.",

    "A truly inclusive meal planner for":
      "Un planificador de comidas verdaderamente inclusivo para",
    "your entire family": "toda tu familia",

    "Kid Friendly": "Pensado para los peques",
    "Gluten Free": "Sin gluten",
    Vegetarian: "Vegetariana",
    "Dairy Free": "Libre de lácteos",
    Vegan: "Vegana",
    "Allergen Free": "Libre de alérgenos",

    "And the best part...It’s": "Y la mejor parte... es",

    Free: "¡Gratis",
    "Ohlive, currently is free":
      "Ohlive, actualmente es gratis e incluso si las cosas cambian en el futuro, es solo para funciones premium nuevas y agregadas. Nunca para el núcleo de nosotros. :)",
    "Our amazingly awesome and saucy newsletter":
      "Nuestro boletín increíblemente impresionante y descarado.",
    "Stay connected":
      "Manténgase conectado con nosotros y aprenda nuevas recetas, consejos y trucos para planificar comidas y cosas geniales que la comunidad de Ohlive está haciendo :)",
    Questions: "Preguntas frecuentes",
    "Signup Now": "Regístrate ahora!",
  },
  "Browse Recipes": {
    Browse: "Navegar ",
    Recipes: "Recetas!",
    "Sit back": "Siéntese, relájese y explore nuestras más de 500 recetas",
    "New recipes added everyday": "Nuevas recetas añadidas todos los días.",
    "Add to Meal Plan": "Agregar al plan de comidas",
    mins: "minutos",
    Search: "Buscar ingredientes o recetas...",
  },
  "Meal Planner": {
    Your: "¡Tu",
    "Weekly Meal Plan": "plan de menú de la semanal!",
    "Make edits":
      "¡Plan de comidas semanal! Realice ediciones, cambios según sus preferencias. Cocina feliz :)",
    "Save and Create Grocery List": "Guardar y crear lista de compras",
    to: "a",
    LUNCH: "COMIDA",
    DINNER: "CENA",

    "Customise your cooking time": "Elige el tiempo de duración de tus recetas",
    "Time per meal": "Tiempo por comida",
    "Max variation": "Variación máxima ",
    "Add Filter": "Añadir filtro",
    "Feature coming Time":
      "¡Característica próximamente! Elija el tiempo de cocción típico que dedica a cada comida y agregue una variación máxima, para que podamos sugerir recetas relevantes.",
    Time: "TIEMPO RECETAS",
    Cost: "COSTE SEMANAL",
    "Energy Efficiency": "EFICIENCIA ENERGETICA",
    "Set a weekly budget": "Elige un presupuesto semanal",
    "Ingredient Type": "Tipo de ingrediente",
    "Max Budget per person": "Máximo por persona",
    "Feature coming Ingredient":
      "¡Característica próximamente! Puedes elegir tu menú en función de cuanto cuesta tus ingredientes. (calculo orientativo a partir de un precio medio de los supermercados mas recurrentes)",
    "Set your energy utilisation": "Calculamos el coste energético de tu plato",
    "Energy Utilisation Level": "Energy Utilisation Level",
    "Feature coming Energy":
      "¡Característica próximamente! Calcula la eficiencia energética media de tus recetas. Elige una franja de consumo energético que te quieres en tus recetas semanalmente y te proponemos un menú que sea eficiente.",
    "Breakfast & Snack": "DESAYUNO MERIENDA",
    "Add breakfast & snacks":
      "Amplia tu menú añadiendo tus desayunos y meriendas",
    "Add breakfast": "Añadir desayuno",
    "Add evening snack": "Añadir meriendas",
    "Feature coming breakfast":
      "¡Característica próximamente!  Amplia tu menú añadiendo tus desayunos y meriendas. Elija si desea agregar desayuno y refrigerios aquí; luego puede personalizar qué días desea incluirlos y la cantidad de miembros de la familia por comida.",
    Budget: "Económico",
    Regular: "Marca",
    Premium: "Gourmet",
  },
  "Grocery List": {
    Your: "Su",
    "Grocery List": "¡Lista de compras!",
    "Select items":
      "Seleccione los elementos creados con su plan de comidas a continuación y descárguelos para su conveniencia.",
    "Select Items To Print": "Seleccionar elementos para imprimir",
    "ITEM NO": "ARTÍCULO NO#",
    "ITEM DETAILS": "DETALLES DEL ARTÍCULO",
    QUANTITY: "CANTIDAD",
    ComingSoon: "¡Característica próximamente !",
    "Ingredients At Your Doorstep": "Servicio Domicilio",
    "You now have": "Ya tienes tu lista de la compra elige donde quieres comprar y que te lo lleven a casa.",
    "Choose delivery area": " - Elige zona de entrega",
    "Purchase from nearby": "Lista de las tiendas, mercados o establecimientos de proximidad.",
    "Purchase from trusted": "Lista de supermercados de confianza.",
  },
  "Add Guest": {
    Ooooh:
      "Ooooh... ¿Tenemos un invitado para comer, verdad? Háganos saber sus detalles :)",
    "Dietary Preferences": "Preferencias alimentarias",
    Vegetarian: "Vegetariana",
    "Non Vegetarian": "No vegetariana",
    Pescetarian: "pescetariano",
    Vegan: "Vegana",
    "Dietary Exclusions": "Exclusiones dietéticas:",
    Egg: "Huevo",
    Lactose: "Lactosa",
    Sugar: "Azúcar",
    Peanuts: "Miseria",
    Tamarind: "Tamarindo",
    "Add More Gues": "Agregar más Gues",
    "Save changes": "Guardar cambios",
    "Clear All Information": "Borrar toda la información",
  },
  "Create Family": {
    "Fill in":
      "¡Complete los detalles de todos los miembros de su familia para que sepamos qué incluir en su plan de comidas semanal!",
    "Dietary Preferences": "Preferencias alimentarias",
    Vegetarian: "Vegetariana",
    "Non Vegetarian": "No vegetariana",
    Pescetarian: "pescetariano",
    Vegan: "Vegana",
    "Dietary Exclusions": "Alergias o intolerancias",
    Egg: "Huevo",
    Lactose: "Lactosa",
    Sugar: "Azúcar",
    "Add more members": "Añadir más miembros",
    Peanuts: "Cacahuete",
    Tamarind: "Tamarindo",
    "Add More Gues": "Agregar más Gues",
    "Save changes": "Guardar",
    "Male":"Hombre",
    "Female": "Mujer",
    "Select": "Seleccionar país",
    "Name": "nombre",
    "Age": "edad",
    "Gender" : "género",
    "Search Age": "Edad de búsqueda",
    "Year Old": "año",
    "Years Old": "años",
  },
  "Search Recipe": {
    Peanuts: "Sin cacahuetes",
    Egg: "sin huevo",
    Lactose: "Sin lactosa",
    Gluten: "Sin gluten",
  },
  "Groceries Downlodeded": {
    "Grocery List": "Lista de compras",
    Downloaded: "Descargada",
    "You can find":
      "Puede encontrar esta lista en su sección ,Historial en su plan de semanas en cualquier momento.",
    "Browse Recipes": "Examinar Recetas",
    "Go Back To Planner View": "Volver a la vista del planificador",
  },
  "NutritionDetails" : {
    "Energy Efficieny": "Eficiencia Energética",
    "Nutrition Facts": "Los datos nutricionales",
    Calories:"Calorías",
    Fats:"Grasas",
    Carbs:"Carbohidratos",
    Protein:"Proteína",
    ComingSoon: "¡Característica próximamente !",
    Note:"Nota: Los datos nutricionales que se muestran son por 1 porción."
  },
  "Loader Page": {
    "Dreaming about food": "soñar con comida",
    "Prank Calling Chefs": "Broma llamando a las cocineras",
    "Hiding their knives": "escondiendo sus cuchillos",
  },
  "Planner Page": {
    Hello: "Hello",
    "Welcome to Ohlive":
      "¡Bienvenidos a Ohlive! Antes de crear su plan de comidas, necesitamos saber un poco sobre usted, su familia y sus preferencias.",
    "Create my Family": "Crear mi Familia",
    "It only": "Solo toma alrededor de 2 minutos... lo prometemos :)",
    "Feel like": "Tener ganas de",
    "browsing our recipes": "navegando por nuestras recetas",
    first: "primera?",
    "View Many More": "Ver muchas más",
  },
  "Recipe Details": {
    "Go back": "Volver al plan",
    " Mediterranean": " Buñuelos Mediterráneos De Calabacín Y Salsa Picante",
    "Add to Meal Plan": "Agregar al plan de comidas",
    minutes: "minutos",
    Servings: "Porciones",
    Ingredients: "Ingredientes",
    Salsa: "Salsa",
    "Courgette Fritters": "Buñuelos De Calabacín",
    Instructions: "Elaboración",
    "Download Recipe PDF": "Descargar PDF Receta",
    "Was this": "¿Te resultó útil esta receta?",
    "Start Cooking": "Empieza a cocinar",
    "Get Recipe On": "Obtener receta en",
    "ContactSendMsg1": "Las instrucciones para cocinar este plato se enviaron por whatsapp a",
    "ContactSendMsg2": "y por correo electrónico a",
    "Begin Cooking": "Empezar a cocinar",
    "Yes, Let’s Begin" : "si, comencemos",
    "No, Let me see the list again": "No, quiero volver a ver la lista",
    "Ok, We’re Good To Go": "ok, estamos listos para irnos",
    "Go Back To Start": "Volver a empezar",
    "Next": "Próximo",
    "Back": "Atrás"
  },
  "Login page": {
    "Planner View": "Vista del planificador",
    "Browse Recipes": "Examinar Recetas",
    Grocery: "Lista Ingredientes",
    Family: "Familia",
    Account: "Cuenta",
    Bookmarks: "Marcadores",
    FAQs: "Preguntas frecuentes",
    "Get In Touch": "Ponerse en contacto",
    Logout: "Cerrar sesión",
    PlanHistory: "Plan Historial",
    CreateFamily: "Crear mi Familia"
  },
  "Meal cart": {
    "Add a Meal": "Agregar una comida",
    mins: "minutos",
    "WEDNESDAY APRIL": "MIÉRCOLES | ABRIL",
    Lunch: "Almuerza",
    Dinner: "Cena",
    Yes: "Sí",
    No: "No",
    "Are you": "¿Estás segura de que quieres eliminar este plato?",
  },

  "Forget Password": {
    "Go back": "Regresa",
    "Forgot it eh": "Olvidé la contraseña. ¿La olvidé ?",
    "Send Reset Link": "Olvidé el enlace de restablecimiento de la contraseña",
    "Waaaait": " Olvidé mi contraseña.¡Espere",
    Login: "Olvidé mi contraseña.Iniciar sesión",
  },
  Login: {
    "Go back": "Regresa",
    "Login with": "Iniciar con",
    Ohlive: "Oh vive",
    "Remember me": "Acuérdate de mí",
    Login: "Acceso",
    "Don’t have": "¿No tienes una cuenta? Avanzar...",
    Signup: "Inscribirse",
    OR: "O",
    "Forgot Password": "Olvidaste tu contraseña",
    EmailPlace: "Tu dirección email",
    LoginEmailErr: "Tu nombre de usuario y contraseña no coinciden. Inténtalo de nuevo.",
    LoginEmailErrPopupbtn : "Intentar otra vez",
  },
  Rejister: {
    "Go back": "Regresa",
    "Signup with": "Registrarte con",
    Ohlive: "Oh vive",
    "Remember me": "Acuérdate de mí",
    "Sign Up": "Inscribirse",
    "Already have": "¿Ya tienes una cuenta? Bien..",
    Login: "Acceso",
    then: "después",
    OR: "O",
    "Login With": "Iniciar con",
    "Save changes": "Guardar cambios",
  },
  "Reset Pass": {
    "Go back": "Regresa",
    "A fresh new": "Una nueva contraseña nueva...",
    "Reset Password": "Restablecer la contraseña",
  },
  "add-guest":
    "Víctor: haga clic aquí para eliminar/agregar a Víctor de esta comida",
    "add-guest-1": ': haga clic aquí para eliminar/agregar a ',
    "add-guest-2": '  de esta comida',
  "add-dish": "Añadir otro plato a esta comida.",
  "add-member":
    "Agregue invitados o cocine más porciones de esta comida para el tupper",
  "meal-delete": "Haz clic aquí si no quieres cocinar esta comida o menú",
  refresh: "Sugerirme un cambio para este plato",
  search: "Quiero buscar otras recetas y reemplazar este plato ",
  "remove-dish": "Eliminar este plato de esta comida",
  home: {
    "welcome-text":
      "La forma más fácil de cuidar de ti y de los tuyos. Cocina y ahorra tiempo y dinero, sin más preocupaciones.",
    "trust-us": "¡Nosotros te ayudamos!",
    "weekly-plan": "Planificamos juntos tu menú semanal",
    "scroll-down": "Desplázate hacia abajo para obtener más información...",
   "invest-time-1": "Invierte tu tiempo en lo más importante. No más preocupaciones, olvídate de pensar qué hacer para comer.",
   "invest-time-2": "Solo disfruta cocinando y comiendo de forma sana y equilibrada.",
      "ohlive-cook":
      "Ohlive se encarga de todo lo demás. Qué cocinar, cómo cocinar o dónde comprar.",
    "how-cook-1": "¿Como",
    "how-cook-2": "cocino?",
    "how-cook-text":
    "No tienes que pensar cómo cocinar, te explicamos el paso a paso de todas las recetas. Asegúrate de activar nuestro “modo de receta” será genial para cocinar juntos. Sé más eficiente energéticamente, marcamos el valor energético de todas las recetas. Puedes elegir el rango de eficiencia y ajustar tu menú y recetas.",
    "what-cook": "What to Cook?",
    "what-cook-text":
    "No más preocupaciones, olvídate de pensar qué hacer para comer. Te ayudamos a planificar tu menú semanal. Según las preferencias de ti y de tu familia elaboramos varias propuestas de menú o puedes hacerte tu propio menú entre las más de 500 recetas. ¡Incluso pensamos en los invitados o tus tuppers! Puedes elegir la opción de añadir desayuno o merienda.",
    "groceries-need": "¿Que ingredientes necesito?",
    "grocery-need-text": "Olvídate de elaborar la lista de la compra, se generara automáticamente Elige donde quieres comprar, hazla sostenible y de proximidad. Y si quieres, olvídate incluso de ir a comprar y que te lo traigan a casa.Tienes varias opciones de ingredientes desde la marca blanca a los productos gourmet . Mira por tu economía, ajusta tu presupuesto del menú de toda la semana.",
    "truly-inclusive": "Ohlive planifica tus comidas de forma verdaderamente inclusiva ",
    "truly-inclusive-text":
      "Planificar tu menú semanal  no tiene sentido si no se hace de forma personalizada, teniendo en cuenta a ti  y el estilo de vida de tu familia. Tenemos en cuenta tanto tus preferencias en la dieta como las posibles intolerancias o alergias de los miembros de tu familia.  Ohlive lo sabía antes de que te despertaras hoy por la mañana y leyeras esto. :)",
  },
  tabs: {
    planner: 'Planifica tu menú',
    recipe: 'Examinar Recetas',
    grocery: 'Lista de la compra',
    family: 'Perfil familiar'
 },
  register: {
    goback: "Volver",
    signuptext: "Regístrate en",
    ohlive: "Ohlive",
    rememberme: "Recuerdame",
    remembertext: "¿Ya tienes una cuenta? Perfecto…Inicia tu ",
    signup: "Sign Up",
    login: "sesión",
    then: ":) entonces",
  },
  form: {
    firstname: "Nombre",
    lastname: "Apellidos",
    selectcountry: "Seleccionar país",
    city: "Ciudad",
    selectlang: "Seleccione el idioma",
    countrycode: "Codigo",
    mobileno: "Número de teléfono móvil	",
    email: "Tu dirección de correo electrónico",
    password: "Tu contraseña",
  },
  browserecipe: {
    browse: "!Navega y échale un",
    recipe: "ojo a nuestras recetas!",
    text: "Siéntate, relájate y explora nuestras más de 500 recetas",
    smtext: "(Nuevas recetas añadidas todos los días.)",
    searchinput: "Buscar por ingredientes o recetas...	",
    searchbtn: "Buscar ",
    showmore: "Mostrar más recetas",
    searchrecipe: "!Navega y échale un ojo a nuestras recetas!",
  },
  ingredients: {
    byingredients: "Por ingredientes",
    bymeal: "Por tipo de comida	",
    meat: "Carne",
    chicken: "Pollo / Pavo	",
    Chicken_Turkey: "Pollo / Pavo",
    lamb: "Carne de res",
    Lamb: "Carne de res",
    beef: "Cerdo",
    Beef: "Cerdo",
    pork: "Otras",
    Pork: "Otras",
    seafood: "Pescado",
    veg: "Verduras",
    egg: "Huevo",
    legumes: "Legumbres",
    starter: "Primero",
    Starter: "Primero",
    maincourse: "Principal/ único",
    Main: "Principal/ único",
    dessert: "Postre",
    Dessert: "Postre",
    1: "Primero",
    2: "Principal/ único",
    3: "Principal/ único",
    4: "Postre",
    Lunch: "COMER",
    Dinner: "CENAR",
  },
  plannerpage: {
    head1: "!Tu plan de",
    head2: "menú  de la semana¡",
    smtext1:
      "Selecciona tu semana que quieres planificar y genera tu plan de menú semanal.",
    smtext2:
      "Haz clic en el botón para crear tu plan semanal completo. También puedes crear tu plan a medida.",
    smtext3:'Te proponemos un plan, con varias opciones, pero puedes cambiarlo como quieras, según  tus preferencias y necesidades. Utiliza los filtros que te ofrecemos para acotar tu selección. Recuerda que puedes quitar miembros de la familia en cada comida, añadir invitados o el tupper para mañana.',
    smtext4:
      "",
    createplan: "Crear tu plan semanal",
    newplan: "Generar Nuevo Plan",
    savelist: "Guardar y ver la lista de la compra",
    viewlist: "Ver lista de la compra",
    "Select Your Week": "elige tu semana",
  },
  planpopup1: {
    head1: "¿Deseas continuar con tu trabajo en curso?",
    btn1: "si, continuar",
    btn2: "No, mostrar mi plan guardado",
  },
  norestrict: "Sin restricciones",
    vegan: "Vegano",
    veg: "Vegetariano (asiático)",
    pesc: "Pescivegetariano",
    egg: "Ovovegetariano",
  planpopup2: {
    mainhead: "Preferencias de mi plan semanal",
    head1: "1. Elije tu tipo de dieta",
    head2: "2. Exclusiones dietéticas",
    head3: "3. Tiempo y preferencias",
    norestrict: "Sin restricciones",
    vegan: "Vegano",
    veg: "Vegetariano (asiático)",
    pesc: "Pescivegetariano",
    egg: "Huevos",
    lact: "Lactosa",
    glu: "Gluten",
    pea: "Cacahuetes",
    eggs: "Huevos",
    beef: "Ternera",
    pork: "Cerdo",
    shellfish: "Marisco",
    fish: "Pescado",
    lunch: "Comer",
    dinner: "Cena",
    weekdays: "Días de semana",
    time: "Tiempo",
    weekends: "Fines de semana",
    desserts: "Postres:",
    "Generate New Plan": "Generar Nuevo Plan",
  },
  faq: {
    que1: "¿Cómo crea Ohlive un plan de alimentación para mí y mi familia?",
    ans1: "Ohlive coge tus preferencias que has marcado tanto en  la dieta como las características de tu grupo familiar y procura crear un menú equilibrado regido por unas directrices nutritivas. Gracias al asesoramiento de nuestro equipo técnico y nuestros chefs buscamos elaboraciones que sean deliciosas y que te cuiden.",
    que2: "¿Puedo cambiar mi plan de comidas a mitad de semana si tengo ganas de comer otra cosa?",
    ans2: "Puedes cambiar tu plan, solo se presenta un reto si has comprado la lista de los ingredientes de la semana, ya que corresponden a las recetas de tu planificación, siempre puedes buscar recetas similares o con los mismos ingredientes para que no te queden los productos en la nevera.",
    que3: "¿Puedo crear un plan para más de una semana?",
    ans3: "El plan es semanal, donde tu puedes elegir las comidas y/o cenas que quieras realizar. No te aconsejamos hacer dos semanas seguidas porque siempre pueden aparecer imprevistos o problemas con la compra de ingredientes por el peligro de que perezcan. Pero con tu plantilla podrías fácilmente  realizarlo.",
    que4: "¿De dónde vienen estas recetas?",
    ans4: "Ohlive cuenta con un equipo de profesionales que buscan las mejores recetas para ti tanto de la cocina tradicional como las tendencias más interesantes. Tenemos la suerte también de contar con la participación de cocineros y cocineras de reconocido prestigio, amigos que comparten sus recetas con nosotros.",
    que5: "Me encantaría ser parte activa de esta comunidad y contribuir con algunas recetas. ¿Yo puedo?",
    ans5: "Por supuesto tú también puedes añadir tu receta en la área personal y poder incorporar el plato de la familia a tu plan. El potaje de tu madre, la paella del tito Raimon o el pastel con soletillas de la abuela.",
    que6: "¿Por qué empezaste Ohlive?",
    ans6: "Porque queria que mis amigos y conocidos pudieran disfrutar de mi pasión por la cocina, sin tener que agobiarse por el que hacer cada semana o de enfrentarse al gran quebradero de cabeza de no hacer siempre lo mismo, o el qué y cuánto he de comprar. Cosas que hacen que cocinar se haga cuesta arriba para las familias en el día a día. Ohlive es fruto de la pandemia de las situaciones que se dieron en aquel periodo donde aparecieron reflexiones, problemas, amigos, vecinos y grandes ideas.",
  },
  account: {
    head: "Tu cuenta",
    smtext:
      "Edita y haz cambios en toda tu vida desde aquí.Es broma... solo algunos detalles básicos",
    smtext2: "Detalles personales:",
    savepassword: "Guardar nueva contraseña",
  },
  grocery: {
    budget: "Marca Blanca",
    regular: "Marcas ",
    premium: "Gourmet",
    item_no: "N.° DE ARTÍCULO",
    item_det: "DETALLES DEL ARTÍCULO",
    qty: "CANTIDAD",
    goback: "Volver a la vista del planificador",
    pdf: 'Lista de la compra  PDF',
    recalculate: 'Recalcular'
  },
  contact: {
    head: 'Ponerse en contacto',
    feel: '¿Tienes ganas de hablar con alguien del equipo?',
    Send:'Envíanos un email:',
    Connect:'Conéctate con nosotros:',
    Sendus:'Envíanos tu consulta:',
    FullName:'Nombre completo',
    EmailAddress:'Tu email',
    MobileNumber:"Tu número de móvil",
    YourMessage: "Tu mensaje...",
    Sendbtn: "Enviar",
  },
  about: {
    head: 'Sobre nosotros',
    subtext: 'Hacemos que cocinar sea divertido y eficiente.',
    subtext1: 'Décadas de experiencia culinaria integradas en el algoritmo y la experiencia de compra de Ohlive.',
    subtext2: '¡Ahora a tu alcance!',
    subtext3: '¡Hola! Somos un grupo de chefs e ingenieros, apasionados por la cocina y alimentación sana, sabrosa y bien planificada.',
    subtext4: '!En Ohlive tenemos la misión de ofrecer una herramienta práctica  que permita a las familias a cocinar ahorrando tiempo, preocupaciones y dinero.  De forma sana, facil y sostenible¡',
    OurVision: 'Nuestra visión :',
    OurVisionText: 'Crear una comunidad que invierta su tiempo en lo importante, cuidar de los suyos. Donde las familias planifiquen comidas saludables y deliciosas, compren ingredientes a los mejores precios y administren sus cocinas de manera eficiente.',
    ChefText: 'Chef y Fundador, Ohlive',
    "Plan Your Week": 'Planifica tu semana',
    "Purchase Ingredients": 'Lista de la compra',
    "Cook Delicious Recipes":'Recetas Deliciosas',
  },
  addmeal: {
    AddMeals:"Añadir comida",
  },
  Bookmarks: {
    Bookmarks:'Marcadores',
    Pick:"Recoge las recetas que descubriste y guardaste en tus búsquedas.",
    ohno:"¡Oh, no! Parece que no tienes nada guardado. ¡No te preocupes! Te tenemos cubierto.",
    Browseover: "Navega  + 500 recetas"
  },
  Account:{
    FirstName:"Nombre",
    LastName:"Apellidos",
    Country:"Seleccionar país",
    City:"Ciudad",
    Language:"CP",
    YourEmailAddress:"Email sera actualizado",
    EmailNotUpdate:"Email sera actualizado",
    Password:"Su ID o correo electrónico",
    YourPassword:"Tu contraseña"
  }
};
