import axios from "axios";
import { TokenService } from "./StorageService";

export default () => {

    const instance = axios.create({
        baseURL: `${process.env.VUE_APP_BASEURL}`
    });

    let ACCESS_TOKEN = TokenService.getAccessToken();

    if (ACCESS_TOKEN) {
        instance.defaults.headers.common["authorization"] = `${ACCESS_TOKEN}`;
    }

    instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

    instance.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            if (!error["response"]) {
                showErrorMessage("Your authorization token is invalid or expired");
                // window.location.replace("/");
                return Promise.reject(error);
            } else if (error.response.status == 403) {
                showErrorMessage("Your authorization token is invalid or expired");
                window.location.replace("/");
            } else if (error.response.status == 401) {
                // window.location.replace("/");
            } else {
                // window.location.replace("/");
            }
            return Promise.reject(error.response);
        }
    );
    return instance;
};

const showErrorMessage = message => {
    console.log('Error ', message);
    // Show Some Notification
};