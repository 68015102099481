export default {
  HeaderFooter: {
    "Trust Us": "Trust us. :)",
    SignupForFree: "Signup For Free",
    Login: "Login",
    "Browse Recipe": "Browse Recipe",
    "How it works": "How it works?",
    "Get in Touch": "Get in Touch",
    About: "About",
    "Browse Recipes": "Browse Recipes",
    Blog: "Blog",
    Copyright: "Copyright",
    "Pvt Ltd": "Ohlive Ventures",
    Rights: "All Rights Reserved.",
    TermsCondition: "Terms & Conditions",
  },
  Footer: {
    "Accept All": "Accept All",
    "We Value": "We value your privacy",
    "We use cookies": "We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. By clicking 'Accept All', you consent to our use of cookies.",
  },
  homePage: {
    "WELCOME TEXT": "The easiest way to plan meals for you and your family.",
    "weltext": "The easiest way to plan your meals, cook, save time and money, without further worries. Try it and be part of our community.",
    "Trust Us": "Trust us. :)",
    "Scroll down to": "Scroll down to learn more...",
    "Invest your time":
      "Invest your time in the important things, like taking care of you and your loved one.",
    you: "you ",
    and: "and ",
    "Ohlive takes care":
      "Ohlive takes care of everything else. What to cook, how to cook or where to shop from.",
    "A truly inclusive": "A truly inclusive meal planner for",
    What: "What",
    "to cook?": "to cook?",
    "What to cook?":
      "A super user friendly weekly meal planner, created around you and your family's preferences and limitations. Go with Ohlive's recommendation or select a recipe from over 500+ recipes.",
    Groceries: "Groceries",
    needed: "needed",
    "Groceries needed":
      "A fully automated list of all the foods you need is prepared as soon as your meal plan is ready, purchased automatically with our food partners. Bring it home hasslefree.",
    How: "How ",
    "A meal plan":
      "A meal plan is pointless if it can’t be designed around you and your family’s lifestyle. Ohlive knew about it before you woke up today morning and read this. :)",
    "How to cook?":
      "Use Ohlive’s very own recipes and make sure you turn on our our uber cool ‘recipe mode’ when you’re just about to cook.",
    "A truly inclusive meal planner for": "A truly inclusive meal planner for",
    "your entire family": "your entire family",
    "Kid Friendly": "Kid Friendly",
    "Gluten Free": "Gluten Free",
    Vegetarian: "Vegetarian",
    "Dairy Free": "Dairy Free",
    Vegan: "Vegan",
    "Allergen Free": "Allergen Free",
    "And the best part...It’s": "And the best part...It’s",
    Free: "Free",
    "Ohlive, currently is free":
      "Ohlive, currently is free and even if things change down the line, it’s only for new and added premium features. Never for the core us. :)",
    "Our amazingly awesome and saucy newsletter":
      "Our amazingly awesome and saucy newsletter.",
    "Stay connected":
      "Stay connected with us and learn new recipes, meal planning tips & tricks and cool thing the Ohlive community’s up to :)",
    Questions: "Frequently Asked Questions",
    "Signup Now": "Signup Now!",
  },
  "Browse Recipes": {
    Browse: "Browse ",
    Recipes: "Recipes!",
    "Sit back": "Sit back, relax and browse through our 500+ recipes",
    "New recipes added everyday": "New recipes added everyday",
    "Add to Meal Plan": "Add to Meal Plan",
    mins: "mins",
    Search: "Search for ingredients or recipes...",
  },
  "Meal Planner": {
    Your: "Your",
    "Weekly Meal Plan": "Weekly Meal Plan!",
    "Make edits":
      "Make edits, changes as per your preferences. Happy Cooking :)",
    "Save and Create Grocery List": "Save and Create Grocery List",
    to: "to",
    LUNCH: "LUNCH",
    DINNER: "DINNER",
    "Create Plan": "Create a New Plan",
    "Create Grocery": "Create Grocery List",
    "Customise your cooking time": "Customise your cooking time",
    "Time per meal": "Time per meal",
    "Max variation": "Max variation",
    "Add Filter": "Add Filter",
    "Feature coming Time":
      "Feature coming soon! Choose the typical cooking time you spend per meal and add a maximum variation, so we can suggest relevant recipes.",
    Time: "Cook Time",
    Cost: "Cost of Ingredients",
    "Energy Efficiency": "Energy Efficiency",
    "Set a weekly budget": "Set a weekly budget",
    "Ingredient Type": "Ingredient Type",
    "Max Budget per person": "Max Budget per person",
    "Feature coming Ingredient":
      "Feature coming soon! You can choose your weekly recipes based on how much your ingredients cost. (indicative calculation based on an average price of the most popular supermarkets)",
    "Set your energy utilisation": "Set your energy utilisation",
    "Energy Utilisation Level": "Energy Utilisation Level",
    "Feature coming Energy":
      "Feature coming soon! Calculate the average energy efficiency of your recipes. Choose from a range of energy consumption that you want in your recipes weekly and we will propose a plan that is efficient.",
    "Breakfast & Snack": "Breakfast & Snack",
    "Add breakfast & snacks": "Add breakfast & snacks",
    "Add breakfast": "Add breakfast",
    "Add evening snack": "Add evening snack",
    "Feature coming breakfast":
      "Feature coming soon! Expand your menu by adding your breakfast and snacks. Choose if you want to add breakfast and snacks here - you can later customise which days you want to include them and the number of family members per meal.",
    Budget: "Budget",
    Regular: "Regular",
    Premium: "Premium",
  },
  "Grocery List": {
    Your: "Your",
    "Grocery List": "Grocery List!",
    "Select items":
      "Select items created, using your meal plan, below and download it for your convenience.",
    "Select Items To Print": "Select Items To Print",
    "ITEM NO": "ITEM NO#",
    "ITEM DETAILS": "ITEM DETAILS",
    QUANTITY: "QUANTITY",
    ComingSoon: "Coming Soon !",
    "Ingredients At Your Doorstep": "Ingredients At Your Doorstep",
    "You now have": "You now have your shopping list, choose where you want to buy from and have them delivered home.",
    "Choose delivery area": " - Choose delivery area",
    "Purchase from nearby": "Purchase from nearby shops, markets or establishments.",
    "Purchase from trusted": "Purchase from trusted supermarkets.",
  },
  "Add Guest": {
    Ooooh: "Have guests over OR want to save portions for the next meal",
    "Dietary Preferences": "Dietary Preferences",

    Vegetarian: "Vegetarian",
    "Non Vegetarian": "Non Vegetarian",
    Pescetarian: "Pescetarian",
    Vegan: "Vegan",
    "Dietary Exclusions": "Dietary Exclusions:",
    Egg: "Egg",
    Lactose: "Lactose",
    Sugar: "Sugar",
    Peanuts: "Peanuts",
    Tamarind: "Tamarind",
    "Add More Gues": "Add More Gues",
    "Save changes": "Save changes",
    "Clear All Information": "Clear All Information",
  },
  "Create Family": {
    "Fill in":
      "Fill in details for all your family members so we know what to plug in your weekly meal plan!",
    "Dietary Preferences": "Dietary Preferences",
    Vegetarian: "Vegetarian",
    "Non Vegetarian": "Non Vegetarian",
    Pescetarian: "Pescetarian",
    Vegan: "Vegan",
    "Dietary Exclusions": "Dietary Exclusions",
    Gluten: "Gluten",
    Egg: "Egg",
    Lactose: "Lactose",
    "Add more members": "Add more members",
    Sugar: "Sugar",
    Peanuts: "Peanuts",
    Tamarind: "Tamarind",
    "Add More Gues": "Add More Gues",
    "Save changes": "Save changes",
    "Male": "Male",
    "Female": "Female",
    "Select": "Select Country",
    "Name": "Name",
    "Age": "Age",
    "Gender" : "Gender",
    "Search Age": "Search Age",
    "Year Old": "Year Old",
    "Years Old": "Years Old",
  },
  "Search Recipe": {
    Peanuts: "Peanuts Free",
    Egg: "Egg Free",
    Lactose: "Lactose Free",
    Gluten: "Gluten Free",
  },
  "Groceries Downlodeded": {
    "Grocery List": "Grocery List",
    Downloaded: "Downloaded",
    "You can find":
      "You can find this list in your ‘History’ section against your weeks plan anytime.",
    "Browse Recipes": "Browse Recipes",
    "Go Back To Planner View": "Go Back To Planner View",
  },
  "NutritionDetails" : {
    "Energy Efficieny": "Energy Efficieny",
    "Nutrition Facts": "Nutrition Facts",
    Calories:"Calories",
    Fats:"Fats",
    Carbs:"Carbs",
    Protein:"Protein",
    ComingSoon: "Coming Soon !",
    Note:"Note: The nutritional facts displayed are as per 1 serving."
  },

  "Loader Page": {
    "Dreaming about food": "Dreaming about food",
    "Prank Calling Chefs": "Prank Calling Chefs",
    "Hiding their knives": "Hiding their knives",
  },
  "Planner Page": {
    Hello: "Hello",
    "Welcome to Ohlive":
      "Welcome to Ohlive! Before we create your meal plan, we need to know a little bit about you, your family and their preferences.",
    "Create my Family": "Create my Family",
    "It only": "It only takes about 2 mins...we promise :)",
    "Feel like": "Feel like",
    "browsing our recipes": "browsing our recipes",
    first: "first?",
    "View Many More": "View Many More",
    "add-meal-planner": "Add Meal Planner",
  },
  "Recipe Details": {
    "Go back": "Go back",
    " Mediterranean": " Mediterranean Courgette Fritters and Spicy Salsa",
    "Add to Meal Plan": "Add to Meal Plan",
    minutes: "minutes",
    Servings: "Servings",
    Ingredients: "Ingredients",
    Salsa: "Salsa",
    "Courgette Fritters": "Courgette Fritters",
    Instructions: "Instructions",
    "Download Recipe PDF": "Download Recipe PDF",
    "Was this": "Was this recipe useful?",
    "Start Cooking": "Start Cooking",
    "Get Recipe On": "Get Recipe On",
    "ContactSendMsg1": "Instructions for cooking for this dish have been sent via whatsapp on",
    "ContactSendMsg2": "and as email to",
    "Begin Cooking": "Begin Cooking",
    "Yes, Let’s Begin" : "Yes, Let’s Begin",
    "No, Let me see the list again": "No, Let me see the list again",
    "Ok, We’re Good To Go": "Ok, We’re Good To Go",
    "Go Back To Start": "Go Back To Start",
    "Next": "Next",
    "Back": "Back"
  },
  "Login page": {
    "Planner View": "Planner View",
    "Browse Recipes": "Browse Recipes",
    Grocery: "Grocery",
    Family: "Family",
    Account: "Account",
    Bookmarks: "Bookmarks",
    FAQs: "FAQs",
    "Get In Touch": "Get In Touch",
    Logout: "Logout",
    PlanHistory: "Plan History",
    CreateFamily: "Create Family"
  },
  "Meal cart": {
    "Add a Meal": "Add a Meal",
    mins: "mins",
    "WEDNESDAY APRIL": "WEDNESDAY | APRIL",
    Lunch: "Lunch",
    Dinner: "Dinner",
    Yes: "Yes",
    No: "No",
    "Are you": "Are you sure you want to delete this plate?",
  },
  "Forget Password": {
    "Go back": "Go back",
    "Forgot it eh": "Forgot it eh",
    "Send Reset Link": "Send Reset Link",
    "Waaaait": " Waaaait! Think I remember it again",
    Login: "Login",
  },
  Login: {
    "Go back": "Go back",
    "Login with": "Login with",
    Ohlive: "Ohlive",
    "Remember me": "Remember me",
    Login: "Login",
    "Don’t have": "Don’t have an account? Go ahead...",
    Signup: "Signup",
    OR: "OR",
    "Login With": "Login With",
    "Forgot Password": "Forget Password",
    EmailPlace: "Your Email Address",
    LoginEmailErr: "Your username and password do not match. Please try again.",
    LoginEmailErrPopupbtn : "Try again",
  },
  Rejister: {
    "Go back": "Go back",
    "Signup with": "Signup with",
    Ohlive: "Ohlive",
    "Remember me": "Remember me",
    "Sign Up": "Sign Up",
    "Already have": "Already have an account? Well..",
    Login: "Login",
    then: "then",
    OR: "OR",
    "Login With": "Login With",
    "Save changes": "Save changes",
  },
  "Reset Pass": {
    "Go back": "Go back",
    "A fresh new": "A fresh new password...",
    "Reset Password": "Reset Password",
  },
  "add-guest": "Victor: Click here to remove / add Victor from this meal",
  "add-guest-1": ': Click to remove/add ',
  "add-guest-2": ' from this meal',
  "add-dish": "Add another dish to this meal",
  "add-member": "Add guests or cook more portions of this meal",
  "meal-delete": "Click here if you don't want to cook this meal",
  refresh: "Suggest a replacement for this dish",
  search: "I want to search recipes and replace this dish ",
  "remove-dish": "Remove this dish from this meal",

  home: {
    "welcome-text": "The easiest way to plan meals for you and your family.",
    "trust-us": "Trust us. :)",
    "weekly-plan": "Create your Weekly Meal Plan",
    "scroll-down": "Scroll down to learn more...",
    "invest-time-1":
      "Invest your time in the important things, like taking care of you and your loved one.",
      "invest-time-2":"The easiest way to plan meals for you and your family..",
    "ohlive-cook":
      "Ohlive takes care of everything else. What to cook, how to cook or where to shop from.",

    "how-cook-1": "How",
    "how-cook-2": "to Cook?",
    "how-cook-text":
      'Use Ohlive’s very own recipes and make sure you turn on our uber cool ‘recipe mode’ when you’re just about to cook.',
    "what-cook": "What to Cook?",
    "what-cook-text":
      "A super user friendly weekly meal planner, created around you and your family's preferences and limitations. Go with Ohlive's recommendation or select a recipe from over 500+ recipes. ",
    "groceries-need": "Groceries needed",
    "grocery-need-text":
      "A fully automated list of all the foods you need is prepared as soon as your meal plan is ready, purchased automatically with our food partners. Bring it home hasslefree. ",
    "truly-inclusive": "A truly inclusive meal planner for",
    "truly-inclusive-text":
      "A meal plan is pointless if it can’t be designed around you and your family’s lifestyle. Ohlive knew about it before you woke up today morning and read this. :)",
  },
  register: {
    goback: " Go back",
    signuptext: "Signup with",
    ohlive: "Ohlive",
    rememberme: "Remember Me",
    remembertext: "Already have an account? Well..",
    signup: "Sign Up",
    login: "Login",
    then: ":) then",
  },
  form: {
    firstname: "First Name",
    fullname: "Your Full Name",
    lastname: "Last Name",
    selectcountry: "Select Country",
    city: "City",
    selectlang: "Select Language",
    countrycode: "Country Code",
    mobileno: "Mobile Number",
    email: "Your Email ID",
    email_add: "Your Email Address",
    msg: "Your Message",
    password: "Your Password",
  },
  browserecipe: {
    browse: "Browse",
    recipe: "Recipes!",
    text: "Sit back, relax and browse through our 500+ recipes",
    smtext: "(New recipes added everyday)",
    searchinput: "Search for ingredients or recipes...",
    searchbtn: "SEARCH",
    searchrecipe: "Search Recipe",
    showmore: "Show More Recipes",
  },
  ingredients: {
    byingredients: "By Ingredients",
    bymeal: "By Meal type",
    meat: "Meat",
    chicken: "Chicken / Turkey",
    Chicken_Turkey: "Chicken / Turkey",
    lamb: "Lamb",
    Lamb: "Lamb",
    beef: "Beef",
    Beef: "Beef",
    pork: "Pork & Other",
    Pork: "Pork & Other",
    seafood: "Seafood",
    veg: "Veg",
    egg: "Egg",
    legumes: "Legumes",
    starter: "Starter",
    Starter: "Starter",
    maincourse: "Main Course",
    dessert: "Dessert",
    Dessert: "Dessert",
    Main: "Main",
    1: "Starter",
    2: "Main",
    3: "Main",
    4: "Dessert",
    Lunch: "Lunch",
    Dinner: "Dinner",
  },
  plannerpage: {
    head1: "Your",
    head2: "Weekly Meal Plan",
    smtext1:
      "Select the week you want to plan for and generate your weekly plan.",
    smtext2:
      "Click on the button to create your complete weekly plan. You could also create a plan by yourself",
    smtext3:
      "Make edits, change the plan below as per your preferences. Happy Cooking :)",
    smtext4:
      "If you do not like this plan, you can always get a new one! If you like it, then go ahead to your grocery list",
    createplan: "Create Weekly Plan",
    newplan: "Generate a New Plan",
    savelist: "Save & View Grocery List",
    viewlist: "View Grocery List",
    "Select Your Week": "Select Your Week",
  },
  tabs: {
     planner: 'Planner View',
     recipe: 'Browse Recipes',
     grocery: 'Grocery',
     family: 'Family'
  },
  planpopup1: {
    head1: "Do you wish to continue your work in progress?",
    btn1: "Yes, continue",
    btn2: "No, Show my Saved Plan",
  },
  "norestrict": "No Restrictions",
    "vegan": "Vegan",
    veg: "Vegetarian (Asian)",
    pesc: "Pescaterian",
    egg: "Eggetarian",
  planpopup2: {
    mainhead: "Weekly Plan Preferences",
    head1: "1. Choose your Diet Type",
    head2: "2. Dietary Exclusions",
    head3: "3. Time and Preferences",
    "norestrict": "No Restrictions",
    "vegan": "Vegan",
    veg: "Vegetarian (Asian)",
    pesc: "Pescaterian",
    egg: "Eggs",
    lact: "Lactose",
    glu: "Gluten",
    pea: "Peanuts",
    eggs: "Eggs",
    beef: "Beef",
    pork: "Pork",
    shellfish: "Shellfish",
    fish: "Fish",
    lunch: "Lunch",
    dinner: "Dinner",
    weekdays: "Weekdays",
    time: "Time",
    weekends: "Weekends",
    desserts: "Desserts",
    "Generate New Plan": "Generate New Plan",
  },
  faq: {
    que1: " How does Ohlive create a meal plan for me and my family? ",
    ans1: "The Ohlive algorithm aims to create a balanced nutrition menu based on your diertary preferences and the characteristics of your family. Thanks to the advice of our technical team and our chefs, we look for preparations that are delicious and that take care of you!.",
    
    que2: " Can I change my meal plan mid week if I feel like eating something else? ",
    ans2: " You can always change your plan mid-week. But if you have already bought ingredients for the week (since they correspond to the recipes in your plan), you should look for similar recipes or recipes with the same ingredients, so that you do not have a mismatch between products in the fridge and what you need at the table. ",

    que3: " Can I create a plan for more than a week? ",
    ans3: " Our planner is weekly, where you can choose the lunches and/or dinners you want to prepare. We do not advise you to plan two weeks down the road - you could end up having unforeseen events (guests coming over) or problems with the purchase of ingredients due to the danger that they may perish. But with our template you could easily plan a couple of days ahead of each week!",

    que4: " Where do these recipes come from? ",
    ans4: " Ohlive has a team of professionals who look for the best recipes for you, both from traditional cuisine and the most interesting trends. We are also lucky to have the participation of renowned chefs and cooks, friends who share their recipes with us.",

    que5: " I’d love to be an active part of this community and contribute some recipes. Can I? ",
    ans5: " Of course you can also add your recipe in the personal area and be able to incorporate the family dish into your plan. Your mama's stew, Uncle Raimon's paella or Grandma's cake with ladybugs!",

    que6: " Why did you start Ohlive? ",
    ans6: "Because I wanted my friends and acquaintances to be able to enjoy my passion for cooking, without having to worry about what to prepare every week or having the kids complain about eating the same dish over & over, or what and how much to buy. Things that make cooking difficult for families on a day-to-day basis. Ohlive is the result of our reflections on problems and irritations faced by our friends, neighbors and colleagues!",
  },
  account: {
    head: "Account",
    smtext:
      "Edit and make changes to your your entire life from here. Kidding...just some basic details",
    smtext2: "Personal Details:",
    savepassword: "Save New Password",
  },
  grocery: {
    budget: "Budget",
    regular: "Regular",
    premium: "premium",
    item_no: "ITEM NO#",
    item_det: "ITEM DETAILS",
    qty: "QUANTITY",
    goback: "Go Back to Weekly Plan",
    pdf: 'Grocery List PDF',
    recalculate: 'Recalculate'
  },
  contact: {
    head: 'Get in Touch',
    feel: 'Feel like speaking with someone from the team?',
    Send:'Send us an email:',
    Connect:'Connect with us',
    Sendus:'Send us a query',
    FullName:'Your Full Name',
    EmailAddress:'Your Email Address',
    MobileNumber:"Your Mobile Number",
    YourMessage: "Your Message...",
    Sendbtn: "Send",
  },
  about: {
    head: 'About Us',
    subtext: 'We make cooking fun and efficient.',
    subtext1: 'Decades of Culinary Experience, baked into Ohlive’s Algorithm & Purchase Experience.',
    subtext2: 'Now at your fingertips !',
    subtext3: 'Hola! We are a group of chefs and engineers, passionate about healthy, tasty and well-planned eating.',
    subtext4: 'At Ohlive, we are on a mission to codify our culinary experience and provide you the greatest cooking and easy experience!',
    OurVision: 'Our Vision :',
    OurVisionText: 'To be the smartest food engine for families to plan healthy & delicious meals, purchase ingredients at the best prices and manage their kitchens efficiently',
    ChefText: 'Chef & Founder, Ohlive',
    "Plan Your Week": 'Plan Your Week',
    "Purchase Ingredients": 'Purchase Ingredients',
    "Cook Delicious Recipes":'Cook Delicious Recipes',
  },
  addmeal: {
    AddMeals:"Add Meal",
  },
  Bookmarks: {
    Bookmarks:'Bookmarks',
    Pick:"Pick up recipes that you discovered and saved along the way.",
    ohno:"Oh no! Looks like you don’t have any saved. Worry not! We got you covered.",
    Browseover: "Browse over 500+ Recipes"
  },
  Account:{
    FirstName:"First Name",
    LastName:"Last Name",
    Country:"Select Country",
    City:"City",
    Language:"Select Language",
    YourEmailAddress:"Your Email Address",
    EmailNotUpdate:"Email won't be updated",
    Password:"Password",
    YourPassword:"Your Password"
  }
};
