<template>
  <div>
    <div v-if="showCookieMessage" class="w-100 cookie-consent-div-main">
      <div class="cookie-consent-div">
        <div class="row justify-content-center align-items-center">
          <div class="col-md-9">
            <h3>{{ $t("Footer.We Value") }}</h3>
            <p>
              {{ $t("Footer.We use cookies") }}
            </p>
          </div>
          <div class="col-md-3 text-md-end text-center mt-3 mt-md-0">
            <button
              class="btn btn-primary btn-cookie-consent"
              @click="AcceptCookie"
            >
              {{ $t("Footer.Accept All") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="container footer pt-5 mt-lg-5">
      <div class="row justify-content-center text-center">
        <div class="col-md-8 col-xl-6">
          <div>
            <img src="@/assets/images/FooterIcon/OhliveLogo.png" alt="" />
          </div>
          <div>
            <ul class="list-1 pt-5">
              <li>
                <a href="/browse-recipes">{{
                  $t("HeaderFooter.Browse Recipes")
                }}</a>
              </li>
              <li>
                <a href="/how-it-works">{{
                  $t("HeaderFooter.How it works")
                }}</a>
              </li>
              <li>
                <a href="/contact"> {{ $t("HeaderFooter.Get in Touch") }}</a>
              </li>
              <li>
                <a href="/about"> {{ $t("HeaderFooter.About") }}</a>
              </li>
              <li>
                <a href="/tnc"> {{ $t("HeaderFooter.TermsCondition") }}</a>
              </li>
              <!-- <li>
                <a> {{ $t("HeaderFooter.Blog") }}</a>
              </li> -->
            </ul>
            <!-- <ul class="my-5 list-2">
              <li>
                <img
                  src="@/assets/images/FooterIcon/Facebookfooter.png"
                  alt=""
                />
              </li>
              <li>
                <img
                  src="@/assets/images/FooterIcon/Twitterfooter.png"
                  alt=""
                />
              </li>
              <li>
                <img
                  src="@/assets/images/FooterIcon/Instagramfooter.png"
                  alt=""
                />
              </li>
              <li>
                <img
                  src="@/assets/images/FooterIcon/Youtubefooter.png"
                  alt=""
                />
              </li>
              <li>
                <img
                  src="@/assets/images/FooterIcon/Pinterestfooter.png"
                  alt=""
                />
              </li>
            </ul> -->
          </div>
          <div>
            <ul class="pb-5 mt-3">
              <li>2022</li>
              <li>© {{ $t("HeaderFooter.Copyright") }}</li>
              <li>{{ $t("HeaderFooter.Pvt Ltd") }}</li>
              <li>{{ $t("HeaderFooter.Rights") }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FooterPage",
  data() {
    return {
      cookieStorageItem: "Y",
      cookieStorageItemDefault: "N",
      showCookieMessage: false,
    };
  },
  beforeMount() {
    const getCookieData = localStorage.getItem("cookieAccepted");
    if (getCookieData == "N" || getCookieData == null) {
      this.DefaultCookie("cookieAccepted");
      this.showCookieMessage = true;
    } else {
      this.showCookieMessage = false;
    }
  },
  methods: {
    AcceptCookie() {
      localStorage.setItem("cookieAccepted", this.cookieStorageItem);
      this.showCookieMessage = false;
    },
    DefaultCookie() {
      localStorage.setItem("cookieAccepted", this.cookieStorageItemDefault);
    },
  },
};
</script>
<style>
.footer ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 5px 16px;
  justify-content: center;
  flex-wrap: wrap;
}
.footer .list-1 a {
  text-decoration: none;
  color: var(--primary);
}
.footer .list-1 a:hover {
  border-bottom: 1px solid var(--secondary);
  color: var(--secondary);
}
.cookie-consent-div-main {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  position: fixed;
  bottom: 0vh;
  z-index: 1000000;
  background: rgba(255, 255, 255, 0.9);
}
.cookie-consent-div {
  padding: 30px 150px 30px 150px;
}

@media (min-width: 1400px) {
  .footer .list-1 {
    gap: 10px 25px;
  }
  .footer .list-2 {
    gap: 30px;
  }
}

@media (max-width: 820px) {
  .cookie-consent-div {
    padding: 30px 70px 30px 70px;
  }
}

@media (max-width: 768px) {
  .cookie-consent-div {
    padding: 20px 30px 30px 30px;
    font-size: 14px;
  }
  .btn-cookie-consent {
    width: 100%;
  }
}
</style>