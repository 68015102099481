<template>
  <div class="change-lang">
    <ul>
      <b-nav-item-dropdown
        id="dropdown-grouped"
        variant="link"
        class="dropdown-language"
        right
      >
        <template #button-content>
          <b-img
            :src="currentLocale.img"
            height="14px"
            width="22px"
            :alt="currentLocale.locale"
          />
          <span class="text-body">{{ currentLocale.name }}</span>
        </template>
        <b-dropdown-item
          v-for="localeObj in locales"
          :key="localeObj.locale"
          @click="setLocale(localeObj)"
        >
          <b-img
            :src="localeObj.img"
            height="14px"
            width="22px"
            :alt="localeObj.locale"
          />
          <span class="ml-50">{{ localeObj.name }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </ul>
  </div>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from "bootstrap-vue";
import i18n from "@/libs/i18n";

export default {
  name: "LocaleSwitch",
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  data() {
    return {
      locales: [
        {
          locale: "en",
          img: require("@/assets/images/flags/en.png"),
          name: "English",
        },
        {
          locale: "es",
          img: require("@/assets/images/flags/es.jpg"),
          name: "Spanish",
        },
      ],
    };
  },
  computed: {
    currentLocale() {
      return this.locales.find((l) => l.locale === this.$i18n.locale);
    },
  },
  mounted() {
    // Check if locale is stored
    const storedLocale = localStorage.getItem("locale");
    this.locales.forEach((l) => {
      if (storedLocale === l.locale) i18n.locale = storedLocale;
    });
  },
  methods: {
    setLocale(localeObj) {
      i18n.locale = localeObj.locale;
      localStorage.setItem("locale", localeObj.locale);
      window.location.reload();
    },
  },
};
</script>

<style>
.change-lang {
  background: transparent;
  border-radius: 8px;
  border: 1px solid var(--primary);
  color: var(--primary);
}
.dropdown-language .text-body {
  margin-left: 10px;
}
.dropdown-language ul li span {
  margin-left: 10px;
}

@media (max-width: 768px) {
  .change-lang {
    padding: 3px 5px;
    margin-right: 15px !important;
    margin: auto;
    font-size: 12px;
  }
  .change-lang .dropdown-item {
    font-size: 12px;
    padding: 5px;
  }
  .change-lang .dropdown-menu {
    --bs-dropdown-min-width: 6rem;
  }
  .change-lang .dropdown-menu.show {
    margin-right: 15px;
  }
}
</style>
