import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import LoadingPlugin from 'vue-loading-overlay'

import router from './router'
import store from './store'
import App from './App.vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "vue-loading-overlay/dist/vue-loading.css";

import './assets/css/style.css'
import './assets/css/common.css'
import './assets/css/responsive.css'
import 'vue-select/dist/vue-select.css';

import 'slick-carousel/slick/slick.css'

// 3rd Party Imports
import i18n from '@/libs/i18n';

import vSelect from "vue-select";

Vue.component("v-select", vSelect);

Vue.config.productionTip = false;
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(LoadingPlugin, {
  loader: "spinner",
  color: "#364d31",
  width: 70,
  zIndex: 1019,
  height: 70,
  backgroundColor: "#000"
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')