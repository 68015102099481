import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
	mode: "history",
	routes: [
		{
			path: "/",
			name: "Home",
			component: () => import("../pages/HomePage.vue"),
		},
		{
			path: "/login",
			name: "Login",
			component: () => import("../pages/auth/Login.vue"),
		},
		{
			path: "/register",
			name: "Register",
			component: () => import("../pages/auth/Register.vue"),
		},
		{
			path: "/about",
			name: "About",
			component: () => import("../pages/AboutPage.vue"),
		},
		{
			path: "/tnc",
			name: "About",
			component: () => import("../pages/TermsConditionPage.vue"),
		},
		{
			path: "/how-it-works",
			name: "HowItWorks",
			component: () => import("../pages/HowItWorks.vue"),
		},
		{
			path: "/faq",
			name: "FAQ",
			component: () => import("../pages/FrequentlyAskedQuestions.vue"),
		},
		{
			path: "/contact",
			name: "Contact",
			component: () => import("../pages/ContactPage.vue"),
		},

		{
			path: "/groceries-list",
			name: "GroceriesList",
			component: () => import("../pages/GroceriesList.vue"),
		},
		{
			path: "/groceries-download",
			name: "GroceriesDownloaded",
			component: () => import("../pages/GroceriesDownloaded.vue"),
		},
		// {
		// 	path: "/forgot-password",
		// 	name: "ForgotPassword",
		// 	component: () => import("../pages/auth/ForgotPassword.vue"),
		// },
		{
			path: "/forgot-password-email",
			name: "ForgotPasswordEmail",
			component: () => import("../pages/auth/ForgotPasswordEmail.vue"),
		},
		{
			path: "/account",
			name: "Account",
			component: () => import("../pages/auth/Account.vue"),
		},

		{
			path: "/reset-password",
			name: "ResetPassword",
			component: () => import("../pages/auth/ResetPassword.vue"),
		},
		{
			path: "/meal-planner",
			name: "MealPlanner",
			component: () => import("../pages/MealPlanner.vue"),
		},
		{
			path: "/plan-history",
			name: "PlanHistory",
			component: () => import("../pages/PlanHistory.vue"),
		},
		{
			path: "/browse-recipes",
			name: "BrowseRecipes",
			component: () => import("../pages/BrowseRecipes.vue"),
		},
		{
			path: "/bookmark-recipes",
			name: "BookmarkRecipes",
			component: () => import("../pages/BookmarkRecipes.vue"),
		},
		{
			path: "/recipe-details/:recipe_id",
			name: "RecipeDetails",
			component: () => import("../pages/RecipeDetails.vue"),
		},
		{
			path: "/cook-view/:recipe_id",
			name: "CookView",
			component: () => import("../pages/CookView.vue"),
		},
		{
			path: "/create-family",
			name: "CreateFamily",
			component: () => import("../pages/CreateFamily.vue"),
		},
		{
			path: "/add-guest",
			name: "AddGuest",
			component: () => import("../pages/AddGuest.vue"),
		},
		{
			path: "/planner-page",
			name: "PlannerPage",
			component: () => import("../pages/PlannerPage.vue"),
		},
		{
			path: "/loader-page",
			name: "LoaderPage",
			component: () => import("../pages/LoaderPage.vue"),
		},
		{
			path: "/loader-page-2",
			name: "LoaderPageTwo",
			component: () => import("../pages/LoaderPageTwo.vue"),
		},
	],
	scrollBehavior() {
        return {x: 0, y: 0}
    }
});

export default router;
