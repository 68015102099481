<template>
  <div id="app">
    <template
      v-if="
        $route.name !== 'Login' &&
        $route.name !== 'Register' &&
        $route.name !== 'ForgotPassword'
      "
    >
      <LoginHeader v-if="isLoggedin" />
      <Header v-else />
    </template>

    <router-view></router-view>
    <template>
      <Footer />
    </template>
  </div>
</template>

<script>
import Header from "@/components/partials/Header.vue";
import Footer from "@/components/partials/Footer.vue";
import LoginHeader from "@/components/partials/LoginHeader.vue";
import AuthServices from "./ApiServices/AuthServices";

export default {
  name: "App",
  components: { Header, Footer, LoginHeader },
  computed: {
    isLoggedin() {
      return this.$store.state.user.user_authenticated;
    },
  },
  mounted() {
    // if url is ar , change state of language to ar, else en
    AuthServices.GetAndSetAccessToken();
    this.isMobile();
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
        
      } else {
        return false;
      }
    },
  },
};
</script>
