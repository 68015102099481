import router from "@/router";
import store from "@/store";
import axios from "axios";
import AxiosIns from "./AxiosIns";
import { TokenService } from "./StorageService";

const AuthServices = {
	async Login(payload) {
		try {
			store.commit("user/SET_USER_AUTHENTICATED", false);
			store.commit("user/SET_USER_DETAILS", {});

			const res = await AxiosIns().post("auth/login", payload);
			if (res && res.data.status) {
				const { refreshToken } = res.data.data;
				TokenService.saveRefreshToken(refreshToken);
				const saveAccessToken = await this.GetAndSetAccessToken();
				if (!saveAccessToken) return null;
			}
			return res;
		} catch (error) {
			console.log("Error in Login Api ", error);
			return null;
		}
	},
	async Register(payload) {
		try {
			const res = await AxiosIns().post("auth/register", payload);
			if (res && res.data.status) {
				const { refreshToken } = res.data.data;
				TokenService.saveRefreshToken(refreshToken);
				const saveAccessToken = await this.GetAndSetAccessToken();
				if (!saveAccessToken) return null;
				return true;
			} else {
				return res;
			}
		} catch (error) {
			console.log("Error in Register Api ", error);
			return null;
		}
	},
	async Update(payload) {
		try {
			const res = await AxiosIns().post("auth/update", payload);
			if (res && res.data.status) {
				return res;
			} else {
				return res;
			}
		} catch (error) {
			console.log("Error in Update Api ", error);
			return null;
		}
	},
	async GetAndSetAccessToken() {
		try {
			const refreshToken = TokenService.getRefreshToken();
			if (!refreshToken) {
				TokenService.removeAccessToken();
				// if (window.location.pathname !== '/') { window.location.replace('/') }
				return null;
			}
			const res = await axios.get(
				`${process.env.VUE_APP_BASEURL}/auth/access-token`,
				{
					headers: {
						authorization: refreshToken,
					},
				}
			);
			if (res && res.data.status) {
				const { accessToken } = res.data.data;
				TokenService.saveAccessToken(accessToken);
				store.commit("user/SET_USER_AUTHENTICATED", true);
				store.dispatch("user/setUserDetails");
				if (window.location.pathname === "/") {
					router.replace("/");
				}
				return true;
			} else {
				TokenService.removeAccessToken();
				TokenService.removeRefreshToken();
				// if (window.location.pathname !== '/') { window.location.replace('/') }
				return null;
			}
		} catch (error) {
			console.log("Error in getting Access Token ", error);
			TokenService.removeAccessToken();
			TokenService.removeRefreshToken();
			// if (window.location.pathname !== '/') { window.location.replace('/') }
			return null;
		}
	},
	logOut() {
		TokenService.removeAccessToken();
		TokenService.removeRefreshToken();
		window.location.replace("/");
	},
	async CheckEmail(payload) {
		store.commit("user/SET_USER_AUTHENTICATED", false);
		store.commit("user/SET_USER_DETAILS", {});

		const res = await AxiosIns().post("auth/check-email", payload);

		return res;
	},
	resetPassword(payload) {
		return AxiosIns().post("auth/reset-password", payload);
	},
};

export default AuthServices;
