import axiosInstance from "./AxiosIns";

export default {
	async getUserDetails() {
		const response = await axiosInstance().get(`user/details`);

		return response;
	},

	async bookmarkRecipe(payload) {
		const { recipe_id } = payload;

		const response = await axiosInstance().post(
			`user/bookmark-recipe/${recipe_id}`
		);

		return response;
	},

	async likeRecipe(payload) {
		const { recipe_id } = payload;

		const response = await axiosInstance().post(
			`/user/like-recipe/${recipe_id}`
		);

		return response;
	},

	async dislikeRecipe(payload) {
		const { recipe_id } = payload;

		const response = await axiosInstance().post(
			`/user/dislike-recipe/${recipe_id}`
		);

		return response;
	},

	async addMobileNumber(body) {

		const response = await axiosInstance().post(`user/add-mobile`, body);

		return response;
	},
};
